import type { BlockIntro } from 'types/block-intro';
import React from 'react';
import {
  Wrap,
  Button,
  ButtonLayoutProps,
  Paragraph,
  Heading,
  RichText,
} from '@leafwell/components';
import Figure from './figure';

const Intro: React.FC<BlockIntro> = ({
  title,
  description,
  descriptionSize,
  topIcon,
  iconSize,
  button,
  buttonColor,
  sectionId,
  alignment = 'center',
  note,
}) => {
  const isCenter = alignment === 'center';
  const isLarge = descriptionSize === 'large';
  return (
    <Wrap
      id={sectionId}
      className={[
        '!gap-y-6 justify-items-start',
        isCenter ? 'md:justify-items-center md:text-center' : '',
      ].join(' ')}
      size="small"
    >
      {topIcon && (
        <Figure {...topIcon} className={iconSize === 'small' ? 'w-6' : ''} />
      )}
      {title?.length > 0 && (
        <Heading
          className="font-sans my-reset prose"
          level="2"
          tagName="h2"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      {description?.length > 0 && (
        <RichText
          className={[
            '!text-lg',
            isLarge ? '!text-3xl sm:!text-5xl !leading-tight' : 'max-w-xl',
          ].join(' ')}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      {note && (
        <Paragraph
          size="small"
          className="text-gray-400"
          dangerouslySetInnerHTML={{ __html: note }}
        />
      )}
      {button && (
        <Button
          href={button.url}
          label={button.title}
          rounded={true}
          target={button.target}
          variant={buttonColor as ButtonLayoutProps['variant']}
        />
      )}
    </Wrap>
  );
};

export default Intro;
